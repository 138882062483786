import { Link } from "gatsby"
import styled from "styled-components"
import { Container, Col, Row, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.color};
  text-decoration: none;
  margin-left: 0;
  margin: 0 10px;
  :hover {
    color: #166f7b;
    text-decoration: none;
  }
`

export const StyledLinkHref = styled.a`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.color};
  text-decoration: none;
  margin-left: 0;
  margin: 0 10px;
  cursor: pointer;
  :hover {
    color: #166f7b;
    text-decoration: none;
  }
`

export const StyledSpan = styled.span`
  background-color: white;
  /* background-color: ${p => (p.bcolor ? "#48a9b5" : "white")}; */
  /* color: ${p => (p.color ? "#48a9b5" : "white")}; */
  color: #48a9b5;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px !important;
  text-decoration: none;
  margin-left: 0;
  margin: 0 10px;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 25px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.15);
  :hover {
    color: #166f7b;
    text-decoration: none;
  }
`

export const ResLink = styled(Link)`
  font-size: ${p => p.fsize || "30px"};
  color: white;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    color: white;
  }
`

export const ResLinkHref = styled(Link)`
  font-size: ${p => p.fsize || "30px"};
  color: white;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    color: white;
  }
`

export const MainPageHeader = styled.div`
  min-height: 620px;
  padding-bottom: 40px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${props => props.background});
  background-position: center bottom, center bottom -110px;
  background-size: 100% 100px, cover;
  background-color: #e7e7e7;
  background-repeat: no-repeat;
  @media (max-width: 414px) {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      url(${props => props.bgres});
    background-position: center bottom, center bottom;
    padding-bottom: 540px !important;
  }
`

export const MainPageH2 = styled.h2`
  color: #48a9b5;
  font-size: 30px;
  @media (max-width: 414px) {
    font-size: 21px;
    text-align: center;
  }
`
export const MainPageContainer = styled(Container)`
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  color: #196e97;
`

export const MainPageCol = styled(Col)`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`

export const LegalDiv = styled.div`
  position: absolute;
  right: 10%;
  bottom: -30%;
  @media (max-width: 576px) {
    position: relative;
    margin-top: -5%;
    margin-left: 15%;
  }
`

export const LegalContainer = styled.div`
  background-color: #f7f7f7 !important;
  width: 350px;
  box-shadow: 0px 7px 15px rgba(15, 44, 87, 0.15);
  border-radius: 20px;
  padding: 50px 30px 30px 20px;
`

export const LegalImg = styled.img`
  margin-bottom: 0;
  margin-right: 15px;
  width: 46px;
  height: 46px;
`

export const LegalTitle = styled.p`
  font-weight: medium;
  margin-bottom: 0;
  color: #48a9b5;
  font-size: "1.1rem";
`

export const LegalDesc = styled.p`
  font-size: 12px;
  margin-bottom: 0;
`

export const LegalDownload = styled.img`
  width: 120px;
  margin-left: 5px;
  margin-right: 5px;
`

export const HiwHeader = styled.div`
  min-height: 700px;
  background-image: linear-gradient(
    180deg,
    transparent 0%,
    rgba(72, 169, 181, 0) 90%,
    rgba(72, 169, 181, 0.5) 100%
  );
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 414px) {
    /* min-height: ${props => props.minheightres}; */
    min-height: 500px;
  }
`

export const HiwContainer = styled(Container)`
  margin-top: 45px;
  width: 100%;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const HiwRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const HiwCol = styled(Col)`
  max-width: 100%;
  flex: 0 0 100%;
`
export const HiwCol2 = styled(Col)`
  flex: column;
  justify-content: center;
  align-items: center;
`

export const HiwHeaderImg = styled.img`
  width: 100%;
  vertical-align: middle;
  border-style: none;
  alt: "How It Works Header";
`
export const HiwH2 = styled.h2`
  color: #196e97;
  text-transform: capitalize;
`

export const HiwP = styled.p`
  color: #196e97;
  margin-top: 0;
  margin-bottom: 1rem;
`

export const CountriesHeader = styled.div`
  min-height: 700px;
  background-image: linear-gradient(
      180deg,
      white 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${props => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const CountriesContainer = styled(Container)`
  flex-grow: 1;
  margin-top: 45px;
  margin-bottom: -40px;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const CountriesDiv = styled.div`
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
  background-color: rgba(72, 169, 181, 0.35);
  min-height: 450px;
  border-radius: 20px;
`

export const CountriesH2 = styled.h2`
  font-size: 50px;
  color: white;
  padding-top: ${props => props.pt};
  @media (max-width: 414px) {
    font-size: ${props => props.fsres || "50px"};
  }
`
export const CountriesP = styled.p`
  font-size: 36px;
  color: white;
  padding-top: ${props => props.pt};
  @media (max-width: 414px) {
    font-size: ${props => props.fsres || "36px"};
  }
`
export const BusinessHeader = styled.div`
  min-height: 700px;
`

export const BusinessContainer = styled(Container)`
  flex-grow: 1;
  margin-top: 45px;
  margin-bottom: -50px;
  @media (max-width: 414px) {
    margin-top: unset;
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const BusinessDiv = styled.div`
  text-align: left;
  @media (max-width: 576px) {
    text-align: center;
  }
`

export const BusinessImgDiv = styled.div`
  @media (max-width: 576px) {
    margin-top: 2rem;
  }
`

export const BusinessTitle = styled.h2`
  color: ${props => props.color || "#48a9b5"};
  display: inline;
  @media (max-width: 576px) {
    text-align: center;
  }
`

export const BusinessBr = styled.br`
  @media (max-width: 576px) {
    display: none;
  }
`

export const BusinessP = styled.p`
  color: #6c6365;
  margin-bottom: 0;
  @media (max-width: 576px) {
    text-align: center;
  }
`

export const LegalBusiness = styled.div`
  margin-top: -50px;
  text-align: right;
`

export const BusinessButtonYellow = styled(Button)`
  width: 257px;
  height: 50px;
  background-color: #ffd27c;
  color: #196e97;
  font-size: 16;
  outline: none;
  border-color: #ffd27c;
  border-radius: 10px;
  margin-top: 10px;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  :hover,
  :active,
  :focus {
    background-color: #ffd27c;
    border-color: #ffd27c;
    color: #48a9b5;
    outline: none;
    filter: brightness(95%);
  }
`

export const BusinessButtonTransparent = styled(Button)`
  width: 257px;
  height: 50px;
  background-color: white;
  color: #48a9b5;
  font-size: 16;
  outline: none;
  border-color: #48a9b5;
  border-radius: 10px;
  margin-top: 10px;
  :hover,
  :active,
  :focus {
    background-color: white;
    border-color: #48a9b5;
    color: #48a9b5;
    outline: none;
    filter: brightness(95%);
  }
`

export const ContactUsContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 150px;

  @media (max-width: 992px) {
    padding-bottom: 80px;
  }
`

export const ContactBoxDiv = styled.div`
  width: max-content;
  background-color: rgba(72, 169, 181, 0.7);
  position: relative;
  border-radius: 20px;
  padding: 1rem;
  ::after {
    content: "";
    width: 2px;
    height: 95px;
    background-color: white;
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 2;
  }
`
export const ContactDetailsDiv = styled.div`
  display: inline-block;
`

export const ContactUsH2 = styled.h2`
  font-family: "Typefez-black";
  color: #102c57;
  font-size: 72px;
  @media (max-width: 768px) {
    font-size: 50px;
  }
`

export const ContactUsP = styled.h3`
  font-family: "Typefez-reg";
  color: #102c57;
  font-size: 18px;
  margin-bottom: 70px;
`

export const FontAwesome = styled(FontAwesomeIcon)`
  color: white;
  position: absolute;
  left: -1.64285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
`

export const Li = styled.li`
  position: relative;
  margin-bottom: 1rem;
  color: white;
`

export const Ul = styled.ul`
  color: #fff;
  list-style-type: none;
  padding-left: 0;
  margin-left: 2.14285714em;
`

export const AboutUsHeader = styled.div`
  min-height: 700px;
  background-image: linear-gradient(
      180deg,
      white 0%,
      rgba(255, 255, 255, 0.3) 30%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${props => props.background});
  background-position: center, center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 414px) {
    min-height: 700px;
    background-image: linear-gradient(
        180deg,
        white 0%,
        rgba(255, 255, 255, 0.3) 30%,
        rgba(255, 255, 255, 0) 100%
      ),
      url(${props => props.bgres});
  }
`

export const AboutUsContainer = styled(Container)`
  margin-top: 30px;
  text-align: center;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
export const AboutUsH2 = styled.h2`
  color: #48a9b5;
  margin-bottom: 1.5rem;
`
export const AboutUsP = styled.p`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 414px) {
    width: 100%;
  }
`

export const AffiliateHeader = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 10%
    ),
    url(${props => props.background});
  background-size: cover;
  background-color: #eae9e7;
  background-repeat: no-repeat;
  background-position: bottom center, top right;
  padding-bottom: 100px;
  @media (max-width: 767px) {
    min-height: ${props => props.minheightres};
    background-image: url(${props => props.bgres});
    background-position: bottom center;
    background-size: 100% auto;
  }
  @media (max-width: 991px) {
    background-position: bottom center, top right 310px;
  }
`

export const AffiliateContainer = styled(Container)`
  margin-top: 3rem;
  flex-grow: 1;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const AffiliateH1 = styled.h2`
  text-align: center;
  color: #48a9b5;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const AffiliateDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd27c;
  display: inline-block;
  margin-left: 0.25rem;
`

export const AffiliateBorder = styled.div`
  width: 60px;
  height: 5px;
  background-color: #ffd27c;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`
export const AffiliateCol = styled(Col)`
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
  }
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

export const AffiliateP = styled.p`
  color: #9f9698;
  font-size: 1.75rem;
  margin-bottom: 3rem;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const AffiliateButtonDiv = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const AffiliateButtonYellow = styled(Button)`
  font-size: 1.25rem;
  line-height: 1.5;
  background-color: #ffd27c;
  border-color: #ffd28c;
  color: #17a2b8;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  :hover {
    background-color: #ffc107;
    color: #212529;
    border-color: #ffc107;
  }
  :active {
    background-color: #ffc107;
    color: #212529;
    border-color: #ffc107;
  }
  :focus {
    background-color: #ffc107;
    color: #212529;
    border-color: #ffc107;
  }
  @media (max-width: 414px) {
    margin-right: 0px;
  }
`

export const AffiliateButtonTrans = styled(Button)`
  background-color: transparent;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #48a9b5;
  border-color: #48a9b5;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  :hover {
    background-color: #48a9b5;
    color: white;
    border-color: #48a9b5;
  }
  :active {
    background-color: #48a9b5;
    color: white;
    border-color: #48a9b5;
  }
  :focus {
    background-color: #48a9b5;
    color: white;
    border-color: #48a9b5;
  }
`
export const AffiliateFormHeader = styled.div`
  min-height: 100vh;
  background-color: white;
  padding-bottom: 100px;
`

export const SalutationAffiliate = styled.div`
  > h2 {
    font-family: "Typefez-bold";
    color: #102c57;
    text-align: center;
    font-size: 50px;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
`

export const AffiliateFormCol = styled(Col)`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
  > p {
    font-family: "Typefez-reg";
    color: #333334;
  }
`

export const AffiliateSubmittedDiv = styled.div`
  align-items: flex-start;
  display: flex;
  > img {
    max-width: 60px;
    margin-right: 1rem;
  }
  > p {
    font-family: "Typefez-reg";
    color: #2ebcd0;
    text-align: left;
  }
`

export const AffiliateSubmittedButton = styled.div`
  margin-top: 3rem;
  display: flex;
  > a {
    background-color: #db64a0;
    color: #fff;
    /* text-transform: uppercase; */
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    @media (min-width: 768px) {
      margin-left: 0 !important;
      margin-right: auto !important;
    }
    @media (min-width: 992px) {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
  }
  > a:hover {
    text-decoration: none;
    filter: brightness(90%);
  }
`

export const RightCol = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 80px;
  }
`

export const DetailsHeader = styled.div`
  padding-top: 120px;
`
export const CheckContainer = styled(Container)`
  padding-bottom: 3rem;
  padding-top: 3rem;
`

export const IconCorrectImg = styled.img`
  max-width: 100px;
  height: 45px;
  display: block;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
`

export const IconBankImg = styled.img`
  width: 50px;
  margin-bottom: 1rem;
`

export const CheckH1 = styled.h2`
  font-family: "Typefez-black";
  font-size: 22px;
  color: #82b4e1;
`

export const CheckP = styled.p`
  color: #102c57;
`

export const YellowDiv = styled.div`
  background-color: #fcb036;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 20px 20px 10px 20px;
  margin-bottom: -10px;
  width: 250px;
  > p {
    font-family: "Typefez-med";
    font-size: 22px;
    color: white;
  }
`

export const CheckTable = styled.table`
  width: 100%;
  color: #212529;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
`

export const TableTdLeft = styled.td`
  color: #48a9b5;
  background-color: white;
  text-align: center;
  padding: 0.75rem !important;
  border: 0;
  width: 169px;
`

export const TableTdRight = styled.td`
  line-height: 50px;
  vertical-align: middle;
  border: 0 !important;
  padding: 0.75rem !important;
  > p {
    color: #196e97;
    font-size: 1.75rem;
  }
`

export const TableBody = styled.tbody`
  background-color: transparent;
  overflow: hidden;
  border-radius: 15px !important;
`

export const TableLabel = styled.label`
  color: #102c57;
  font-family: "Typefez-black";
  font-size: 16px;
  background-color: white;
  border: 0 !important;
  padding: ${p => p.padding || "10px 30px"};
  vertical-align: top;
  margin-bottom: 0;
  border-top-left-radius: ${p => p.borderTopLeftRadius};
  border-bottom-left-radius: ${p => p.borderBottomLeftRadius};
`

export const TableValue = styled.td`
  border: 0 !important;
  padding: ${p => p.padding || "10px 20px"};
  background-color: #82b4e1 !important;
  color: white;
  font-family: "Typefez-med";
  border-top-right-radius: ${p => p.borderTopRightRadius};
  border-bottom-right-radius: ${p => p.borderBottomRightRadius};
`
export const DisclaimerText = styled.small`
  color: #828282;
  font-family: "Typefez-med";
  > strong {
    color: #828282;
    font-family: "Typefez-black";
  }
`

export const DealDiv = styled.div`
  position: relative;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
`

export const DealWrap = styled.div`
  background-color: #102c57;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 40px 30px 30px 30px;
  text-align: center;
  position: relative;
  > h2 {
    color: white;
    font-size: 22px;
    font-family: "Typefez-black";
  }
  > p {
    color: white;
    font-size: 16px;
  }
`

export const LogoImg = styled.img`
  margin-top: -80px;
`

export const GlobeImg = styled.img`
  min-width: 500px;
  position: absolute;
  z-index: -2;
  top: 250px;
  right: -200px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const BlueButton = styled(Button)`
  width: 100% !important;
  background-color: #48a9b5;
  border-color: #48a9b5;
  color: #ffd27c;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  :hover {
    background-color: #48a9b5;
    border-color: #48a9b5;
    filter: brightness(90%);
    color: #ffd27c;
  }
  :active {
    background-color: #48a9b5;
    border-color: #48a9b5;
  }
  :focus {
    background-color: #48a9b5;
    border-color: #48a9b5;
  }
`
export const TransparentButton = styled(Button)`
  width: 100% !important;
  background-color: transparent;
  border-color: #48a9b5;
  color: #48a9b5;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  :hover {
    background-color: #48a9b5;
    border-color: #48a9b5;
    color: #fff;
  }
  :active {
    background-color: #48a9b5;
    border-color: #48a9b5;
  }
  :focus {
    background-color: #48a9b5;
    border-color: #48a9b5;
  }
`

export const SpanInline = styled.span`
  display: none;
  @media (min-width: 992px) {
    display: inline;
  }
`

export const BicInput = styled.input`
  height: 48px;
  max-width: 400px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #333334;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
`

export const BicButton = styled(Button)`
  max-width: 400px;
  background-color: #48a9b5;
  border-color: #48a9b5;
  color: #ffd27c;
  padding: 1rem;
  width: 100%;
  border-radius: 0;
  font-weight: 600;
  :hover {
    background-color: #48a9b5;
    border-color: #48a9b5;
    color: #ffd27c;
    filter: brightness(90%);
  }
  :focus {
    background-color: #48a9b5;
    border-color: #48a9b5;
    color: #ffd27c;
  }
  :active {
    background-color: #48a9b5;
    border-color: #48a9b5;
    color: #ffd27c;
  }
`

export const BicUl = styled.ul`
  padding-inline-start: 20px;
`

export const BicLiWrong = styled.li`
  font-family: "Typefez-reg";
  font-size: 14px;
  color: #333334;
`

export const BicLiCorrect = styled.li`
  font-size: 14px;
  color: #48a9b5;
`
export const BusinessDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd27c;
  margin-left: 0.25rem;
  display: inline-block;
`
